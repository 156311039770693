import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgC1 from "../../assets/image/products/raspberry.png";
import imgC2 from "../../assets/image/products/bilberry.png";
import imgC3 from "../../assets/image/products/strawberry_fix.png";
import imgC4 from "../../assets/image/products/blackberry.png";
import imgC5 from "../../assets/image/products/blackcurrant.png";
import imgC6 from "../../assets/image/products/redcurrant.png";
import imgC7 from "../../assets/image/products/cranberry.png";
import imgC8 from "../../assets/image/products/lingonberry.png";

const BerriesList = () => {
  return (
    <>
      {/* <!-- Categories Area --> */}
      <div className="category-area mt-n29 pb-19 pb-lg-21">
        <Container>
          <Row>
            <Col
              md="6"
              lg="4"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="750"
            >
              <Link to="/">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Raspberry
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC1} alt="" className="" />
                  </div>
                </div>
              </Link>

              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Blackberry
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC4} alt="" className="" />
                  </div>
                </div>
              </Link>
              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Lingonberry
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC8} alt="" className="" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              md="6"
              lg="4"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="750"
            >
              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Bilberry
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC2} alt="" className="" />
                  </div>
                </div>
              </Link>
              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Black currant
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC5} alt="" className="" />
                  </div>
                </div>
              </Link>
              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Cranberry
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC7} alt="" className="" />
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              md="6"
              lg="4"
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="750"
            >
              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Strawberry
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC3} alt="" className="" />
                  </div>
                </div>
              </Link>
              <Link to="/#">
                <div className="single-category w-100 rounded-10 bg-gray-2 d-inline-block px-12 pt-9 pb-12 mb-9 gr-hover-shadow-6 ">
                  <div className="case-content mb-9">
                    <h3 className="category-title gr-text-6 mb-3 text-blackish-blue">
                      Red Currant
                    </h3>
                  </div>
                  <div className="category-img text-center">
                    <img src={imgC6} alt="" className="" />
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BerriesList;
